import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Col, Container, Row, Button, Nav, NavItem, NavLink } from "reactstrap";
import CryptoJS from 'crypto-js';
import { useHistory } from 'react-router-dom';

function FeatureOne() {
  const [clients, setClients] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All"); // Default to show all clients

  const history = useHistory();
  const encryptClientId = (clientId) => {
    const encrypted = CryptoJS.AES.encrypt(clientId.toString(), process.env.REACT_APP_ENCRYPTION_KEY).toString();
    return encodeURIComponent(encrypted); // To make it URL safe
  };

  const handleManage = (clientId) => {
    const encryptedClientId = encryptClientId(clientId);
    history.push(`/UploadExcel/${encryptedClientId}`);
  };

  const handleManageNew = (clientId) => {
    const encryptedClientId = encryptClientId(clientId);
    history.push(`/AuctionManage/${encryptedClientId}`);
  };

  const handleManageLatest = (clientId) => {
    const encryptedClientId = encryptClientId(clientId);
    history.push(`/Export/${encryptedClientId}`);
  };

  useEffect(() => {
    // Fetch data from the API endpoint
    axios.get('https://bidtowin-heroku-825dede58502.herokuapp.com/getAuctionsData')
      .then(response => {
        // Store the fetched data in the state
        setClients(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); // Run this effect only once when the component mounts

  useEffect(() => {
    // Update remaining time every second
    const interval = setInterval(() => {
      setClients((prevClients) =>
        prevClients.map(client => ({ ...client }))
      );
    }, 1000);
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const handleDelete = (clientId) => {
    // Make an API call to delete the client
    axios.delete(`https://bidtowin-heroku-825dede58502.herokuapp.com/auctions/${clientId}`)
      .then(response => {
        // If deletion is successful, update the clients array to remove the deleted client
        setClients(clients.filter(client => client.Id !== clientId));
      })
      .catch(error => {
        console.error('Error deleting Auction:', error);
        // Handle error, such as displaying an error message
      });
  };

  const filterClientsByCategory = (client) => {
    const currentDate = new Date();
    const endDate = new Date(client.Enddate);
    const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    if (selectedCategory === "Expired") {
      return endDate < today;
    } else if (selectedCategory === "Upcoming") {
      return endDate > today;
    } else if (selectedCategory === "Today") {
      return endDate.toDateString() === today.toDateString();
    }
    return true; // Show all clients if no category is selected
  };

  // Function to get the count of records for each category
  const getCountByCategory = (category) => {
    const currentDate = new Date();
    const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    
    if (category === "Upcoming") {
      return clients.filter(client => new Date(client.Enddate) > today).length;
    } else if (category === "Today") {
      return clients.filter(client => new Date(client.Enddate).toDateString() === today.toDateString()).length;
    } else if (category === "Expired") {
      return clients.filter(client => new Date(client.Enddate) < today).length;
    } else {
      return clients.length; // All clients
    }
  };

  // Function to calculate the remaining time
  const getRemainingTime = (endDate, endTime) => {
    const endDateTime = new Date(`${endDate}T${endTime}`);
    const now = new Date();
    const timeDiff = endDateTime - now;

    if (timeDiff <= 0) return "Expired";

    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <div style={{ paddingTop: 90 }}>
      <Container>
        <Row className='align-items-center'>
          <Col xl="12">
            <div>
              <div style={{ marginBottom: 10 }}>
                <h5 style={{ fontWeight: "bold", color: "brown", marginBottom: 0 }}>BidToWin Management Dashboard</h5>
                <p style={{ color: 'GrayText' }}>Access all tools and resources for hosting auctions on Bidtowin App Services.</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <div>
              <div style={{ padding: 10, margin: "10px 2px", border: "solid #fafafa 2px", borderRadius: "5px", minHeight: 30, backgroundColor: "white" }}>
               

                {/* Tabs for category */}
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={selectedCategory === "Upcoming" ? "active" : ""}
                      onClick={() => setSelectedCategory("Upcoming")}
                    >
                      Live Auctions ({getCountByCategory("Upcoming")})
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={selectedCategory === "Today" ? "active" : ""}
                      onClick={() => setSelectedCategory("Today")}
                    >
                      Expiring Today ({getCountByCategory("Today")})
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={selectedCategory === "Expired" ? "active" : ""}
                      onClick={() => setSelectedCategory("Expired")}
                    >
                      Expired Auctions ({getCountByCategory("Expired")})
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={selectedCategory === "All" ? "active" : ""}
                      onClick={() => setSelectedCategory("All")}
                    >
                      All Auctions ({getCountByCategory("All")})
                    </NavLink>
                  </NavItem>
                </Nav>

                <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '20px' }}>
                  <thead>
                    <tr>
                      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color: "white", backgroundColor: "brown", fontWeight: "normal", fontSize: "0.9rem" }}>UID</th>
                      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color: "white", backgroundColor: "brown", fontWeight: "normal", fontSize: "0.9rem" }}>Auction</th>
                      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color: "white", backgroundColor: "brown", fontWeight: "normal", fontSize: "0.9rem" }}>Client</th>
                      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color: "white", backgroundColor: "brown", fontWeight: "normal", fontSize: "0.9rem" }}>Category</th>
                      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color: "white", backgroundColor: "brown", fontWeight: "normal", fontSize: "0.9rem" }}>End Date</th>
                      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color: "white", backgroundColor: "brown", fontWeight: "normal", fontSize: "0.9rem" }}>End Time</th>
                      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color: "white", backgroundColor: "brown", fontWeight: "normal", fontSize: "0.9rem" }}>Location</th>
                      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color: "white", backgroundColor: "brown", fontWeight: "normal", fontSize: "0.9rem" }}>Remaining Time</th>
                      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color: "white", backgroundColor: "teal", fontWeight: "normal", fontSize: "0.9rem" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clients.filter(filterClientsByCategory).map(client => (
                      <tr key={client.Id} style={{ border: '1px solid #dddddd' }}>
                        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left', fontSize: "0.9rem", color: "black" }}><b>BTW{client.Id}</b></td>
                        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left', fontSize: "0.9rem", color: "black" }}><b>{client.Name}</b></td>
                        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left', fontSize: "0.9rem", color: "black" }}>{client.Client}</td>
                        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left', fontSize: "0.9rem", color: "black" }}>{client.Category}</td>
                        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left', fontSize: "0.9rem", color: "black" }}>{client.Enddate}</td>
                        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left', fontSize: "0.9rem", color: "black" }}>{client.Endtime}</td>
                        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left', fontSize: "0.9rem", color: "black" }}>{client.Location}</td>
                        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left', fontSize: "0.9rem", color: "black" }}>{getRemainingTime(client.Enddate, client.Endtime)}</td>
                        <td style={{ border: '1px solid #dddddd', padding: '2px 8px', textAlign: 'left' }}>
                          <Button className="btn-warning btn-small" style={{ padding: "2px 5px", color: "black" }} onClick={() => handleManageNew(client.Id)}>View</Button>  <Button className="btn-danger btn-small" style={{ padding: "2px 5px" }} onClick={() => {
                            if (window.confirm("You are removing a record permanently! Are you sure you want to delete this record?")) {
                              handleDelete(client.Id);
                            }
                          }}>Delete</Button><br/>
                          <Button className="btn-primary btn-small" style={{ padding: "2px 5px", color: "white" }} onClick={() => handleManage(client.Id)}>Upload</Button>&nbsp;
                          <Button className="btn-secondary btn-small" style={{ padding: "2px 5px", color: "white" }} onClick={() => handleManageLatest(client.Id)}>Export</Button> 
                         
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export { FeatureOne };
