import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { Col, Container, Row } from "reactstrap";
import { useHistory } from 'react-router-dom';
import * as XLSX from 'xlsx';

const FeatureOne = () => {
  const { clientId } = useParams();
  const [decryptedClientId, setDecryptedClientId] = useState('');
  const [auctionData, setAuctionData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [clientName, setClientName] = useState('');
  const [buyersMap, setBuyersMap] = useState({});  // Store buyers data

  const history = useHistory();
  
  const bgColors = {
    "light": "white",
    "greyish": "#2F2F2E"
  };

  // Decrypt Client ID
  useEffect(() => {
    const decryptClientId = (encryptedClientId) => {
      try {
        const decrypted = CryptoJS.AES.decrypt(
          decodeURIComponent(encryptedClientId),
          process.env.REACT_APP_ENCRYPTION_KEY
        );
        return decrypted.toString(CryptoJS.enc.Utf8);
      } catch (error) {
        console.error('Decryption failed:', error);
        return 'Invalid Client ID';
      }
    };

    if (clientId) {
      const decryptedId = decryptClientId(clientId);
      setDecryptedClientId(decryptedId);
    }
  }, [clientId]);

  // Fetch Auction Data
  useEffect(() => {
    const fetchAuctionData = async () => {
      try {
        const response = await axios.get(`https://bidtowin-heroku-825dede58502.herokuapp.com/getAuctionDataList/${decryptedClientId}`);
        setAuctionData(response.data);
        setFilteredData(response.data);
      } catch (error) {
        console.error('Error fetching auction data:', error);
      }
    };

    if (decryptedClientId) {
      fetchAuctionData();
    }
  }, [decryptedClientId]);

  // Fetch Buyers Data & Create a Mapping
  useEffect(() => {
    const fetchBuyersData = async () => {
      try {
        const response = await axios.get("https://bidtowin-heroku-825dede58502.herokuapp.com/getBuyersData"); 
        const buyersData = response.data;

        // Create a mapping { email: name }
        const buyersMapping = {};
        buyersData.forEach(buyer => {
          buyersMapping[buyer.Email] = buyer.Name;
        });

        setBuyersMap(buyersMapping);
      } catch (error) {
        console.error('Error fetching buyers data:', error);
      }
    };

    fetchBuyersData();
  }, []);

  // Fetch Client Name
  useEffect(() => {
    const fetchClientName = async () => {
      try {
        const response = await axios.get(`https://bidtowin-heroku-825dede58502.herokuapp.com/getClientName/${decryptedClientId}`);
        setClientName(response.data.name);
      } catch (error) {
        console.error('Error fetching client name:', error);
      }
    };

    if (decryptedClientId) {
      fetchClientName();
    }
  }, [decryptedClientId]);

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData.map(auction => ({
      "Auction Id": auction.client_id,
      "Vehicle Id": auction.Id,
      "Base Reserve Price": auction.BASE_PRICE_RESERVE_PRICE,
      "Target Price": auction.TARGET_PRICE,
      "Win Price": auction.BID_START_PRICE,
      "Win User": buyersMap[auction.BID_USER] || auction.BID_USER,
      "Serial No.": auction.SNO,
      "Region": auction.REGION,
      "City": auction.CITY,
      "Agreement No.": auction.AGREMENT_NUMBER,
      "Customer Name": auction.CUSTOMER_NAME,
      "Make": auction.MAKE,
      "Model": auction.MODEL,
      "Variant": auction.VARIENT,
      "Registration No.": auction.REG_NO,
      "Year Of Manufacture": auction.YOM,
      "Engine No.": auction.ENGINE_NO,
      "Chassis No.": auction.CHASSIS_NO,
      "RC Availability": auction.RC_AVAILABLE,
      "Parking Yard Name": auction.PARKING_YARD_NAME,
      "Parking Yard Address": auction.PARKING_YARD_ADDRESS,
      "Fuel Type": auction.FUEL_TYPE,
      "Odometer": auction.ODOMETER,
      "Quote Validity": auction.QUOTE_VALIDITY,
      "State": auction.STATE,
      "Repo Date": auction.REPO_DATE,
      "Parking Charge Per Day": auction.PARKING_CHARGE_PER_DAY,
      "Vehicle Condition": auction.VEHICLE_CONDITION,
      "Documents": auction.DOCUMENTS,
      "Remarks": auction.REMARKS
    })));
  
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Auction Data");
    XLSX.writeFile(workbook, `AuctionData_${decryptedClientId}.xlsx`);
  };

  return (
    <div style={{ paddingTop: 90 }}>
      <Container>
        <Row className='align-items-center'>
          <Col xl="12">
            <h5 style={{ fontWeight: "bold", color: "brown", marginBottom: 5 }}>
              Export Auction Data - BTW{decryptedClientId} - {clientName}
            </h5>
            <p style={{ color: 'GrayText' }}>The following controls help in downloading raw data as an Excel file for the selected auction.</p>
            <button 
  onClick={exportToExcel} 
  style={{
    backgroundColor: "green", 
    color: "white", 
    padding: "10px 15px", 
    border: "none", 
    cursor: "pointer", 
    marginBottom: "10px",
    borderRadius: "5px"
  }}
>
  Export to Excel
</button>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <div style={{ padding: 10, margin: "10px 2px", border: "solid #fafafa 2px", borderRadius: "5px", backgroundColor: bgColors.light, overflowX: "auto" }}>
              <div style={{ width: "100%", overflowX: "auto" }}>
                <table style={{ 
                  borderCollapse: "collapse", 
                  width: "100%", 
                  minWidth: "1200px",
                  tableLayout: "auto" 
                }}>
                  <thead>
                    <tr>
                      {[
                        "Auction Id", "Vehicle Id", "Base Reserve Price", "Target Price", 
                        "Win Price", "Win User", "Serial No.", "Region", "City", 
                        "Agreement No.", "Customer Name", "Make", "Model", "Variant", 
                        "Registration No.", "Year Of Manufacture", "Engine No.", 
                        "Chassis No.", "RC Availability", "Parking Yard Name", 
                        "Parking Yard Address", "Fuel Type", "Odometer", "Quote Validity", 
                        "State", "Repo Date", "Parking Charge Per Day", "Vehicle Condition", 
                        "Documents", "Remarks"
                      ].map((header, index) => (
                        <th key={index} style={{ 
                          border: "1px solid #dddddd", 
                          padding: "8px", 
                          textAlign: "left", 
                          color: "white", 
                          backgroundColor: "brown", 
                          fontWeight: "normal", 
                          fontSize: "0.9rem", 
                          whiteSpace: "nowrap" 
                        }}>
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((auction) => (
                      <tr key={auction.Id} style={{ border: "1px solid #dddddd" }}>
                        {[
                          auction.client_id, auction.Id, auction.BASE_PRICE_RESERVE_PRICE, 
                          auction.TARGET_PRICE, auction.BID_START_PRICE, 
                          buyersMap[auction.BID_USER] || auction.BID_USER,  // Show name instead of email
                          auction.SNO, auction.REGION, auction.CITY, auction.AGREMENT_NUMBER, 
                          auction.CUSTOMER_NAME, auction.MAKE, auction.MODEL, auction.VARIENT, 
                          auction.REG_NO, auction.YOM, auction.ENGINE_NO, auction.CHASSIS_NO, 
                          auction.RC_AVAILABLE, auction.PARKING_YARD_NAME, 
                          auction.PARKING_YARD_ADDRESS, auction.FUEL_TYPE, auction.ODOMETER, 
                          auction.QUOTE_VALIDITY, auction.STATE, auction.REPO_DATE, 
                          auction.PARKING_CHARGE_PER_DAY, auction.VEHICLE_CONDITION, 
                          auction.DOCUMENTS, auction.REMARKS
                        ].map((data, index) => (
                          <td key={index} style={{ 
                            border: "1px solid #dddddd", 
                            padding: "8px", 
                            textAlign: "left", 
                            fontSize: "0.9rem", 
                            color: "black", 
                            whiteSpace: "nowrap" 
                          }}>
                            {data}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export { FeatureOne };