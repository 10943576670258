import React, { Suspense } from "react"
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom'
import { useCookies } from "react-cookie";

import IndexOne from "./pages/IndexOne"
import Privacy from "./Privacy"
import Terms from "./Terms"
import Login from "./Login"
import Registration from "./Registration"
import Error from "./Error"
import Dashboard from "./Dashboard"
import Settings from "./Settings"


import Auctions from "./Auctions"
import Auctionlist from "./Auctionlist"

import Monitoring from "./Monitoring"
import Interests from "./Interests"
import Manage from "./Manage"

import Clients from "./Clients"
import Categories from "./Categories"
import UploadExcel from "./UploadExcel"
import AuctionManage from "./AuctionManage"
import BuyerManage from "./BuyerManage"
import AuctionReport from "./AuctionReport"
import BuyerManagement from "./BuyerManagement"
import VehicleData from "./VehicleData"

import ExportData from "./ExportData"

const NotFound = () => {
  return (
    <Error />
  );
};


function App() {

  const [cookies] = useCookies(["token"]);
  const isAuthenticated = cookies.token !== undefined;
  

  return (
    <Suspense fallback={<div/>}>
    <Router basename="/">
      <Switch>
      <Route exact path="/UploadExcel/:clientId">
          {isAuthenticated ? <UploadExcel /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/AuctionManage/:clientId">
          {isAuthenticated ? <AuctionManage /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/BuyerManage/:clientId">
          {isAuthenticated ? <BuyerManage /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/BuyerManagement/:clientId">
          {isAuthenticated ? <BuyerManagement /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/VehicleData/:clientId">
          {isAuthenticated ? <VehicleData /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/Export/:clientId">
          {isAuthenticated ? <ExportData /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/AuctionReport">
          {isAuthenticated ? <AuctionReport /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/dashboard">
          {isAuthenticated ? <Dashboard /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/settings">
          {isAuthenticated ? <Settings /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/auctions">
          {isAuthenticated ? <Auctions /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/auctionlist">
          {isAuthenticated ? <Auctionlist /> : <Redirect to="/login" />}
        </Route>
        
        <Route exact path="/monitoring">
          {isAuthenticated ? <Monitoring /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/interests">
          {isAuthenticated ? <Interests /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/manage">
          {isAuthenticated ? <Manage /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/clients">
          {isAuthenticated ? <Clients /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/categories">
          {isAuthenticated ? <Categories /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/login">
          {isAuthenticated ? <Redirect to="/dashboard" /> : <Login />}
        </Route>

        <Route path="/privacy" component={Privacy} exact/>
        <Route path="/terms" component={Terms} exact/>
        <Route path="/registration" component={Registration} exact/>
        <Route path="/" component={IndexOne} exact />
        <Route component={NotFound} />
      </Switch>
    </Router>
  </Suspense>
  );
}

export default App;
